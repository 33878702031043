<template>
    <default-template>
        <v-layout class="fill-height justify-center align-start background-content">
            <slot />
        </v-layout>
    </default-template>
</template>
<script>
import DefaultTemplate from "../DefaultTemplate.vue";
export default {
    components: {
        DefaultTemplate
    }
}
</script>
<style scoped>
.background-content {
    background: #f1f2f7 url('/images/blueticket/login-bg.png') no-repeat;
    background-position: center;
    background-size: cover;
}
</style>